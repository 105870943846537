import { Injectable } from '@angular/core';
import { RouterLinkProvider } from '@core/routing/routes/providers/router-link.provider';
import { NavigationExtras, Router } from '@angular/router';
import { Nillable } from '@core/utils/nil/nillable';
import { RouterLink } from '@core/routing/routes/types/router-link';
import { valueOrUndefined } from '@core/utils/nil/value-or-undefined';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { blank } from '@core/utils/rxjs/blank';

@Injectable({
  providedIn: 'root',
})
export class NavigatorService {
  constructor(
    private readonly provider: RouterLinkProvider,
    private readonly router: Router
  ) {}

  navigateByRouterLink(
    routerLink: (provider: RouterLinkProvider) => RouterLink,
    extras?: Nillable<NavigationExtras>
  ): void {
    void this.router.navigate(
      routerLink(this.provider),
      valueOrUndefined(extras)
    );
  }

  navigateByRouterLinkAsync(
    routerLink: (provider: RouterLinkProvider) => RouterLink,
    extras?: Nillable<NavigationExtras>
  ): Observable<void> {
    return fromPromise(
      this.router.navigate(routerLink(this.provider), valueOrUndefined(extras))
    ).pipe(blank());
  }
}
